import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./translation/en.json";
import translationTA from "./translation/ta.json";

export const resources = {
  en: { translation: translationEN },
  ta: { translation: translationTA },
} as const;

// Make sure to call this function only once, e.g., in your main index.js or index.tsx
export const initializeI18n = (defaultLanguage: string) => {
  i18n.use(initReactI18next).init({
    resources,
    lng: defaultLanguage,
    fallbackLng: "en",
    interpolation: { escapeValue: false },
  });
};

export default i18n;