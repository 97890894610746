import { t } from "i18next";
import {  useState } from "react";
import Modal from "react-modal";
import Switch from "react-switch";
import { initializeI18n } from "../../src/i18n";
import Appstore from "../assets/Appstore.png";
import Black from "../assets/Black.png";
import Playstore from "../assets/Playstore.png";
import BlackWeb from "../assets/WebBlack.png";
import MKSLogo from "../assets/mkslogo.png";
import MobileDisplay from "../assets/mobileMockup1.png";
import QR from "../assets/qr.png";
import RedirectToStore from "../redirect";


const youtubeVideos = [
  {
    id: 1,
    link: "https://www.youtube.com/embed/BDMJo6340jA?si=N8sYspN_41aCMVLY",
  },
  {
    id: 2,
    link: "https://www.youtube.com/embed/-GW9so599GI?si=N0_72YQfzdQV6qB0",
  },
];
const HomePage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checked, setChecked] = useState(false);



  const CustomCheckedIcon = () => (
    <div style={{ color: "white", paddingTop: "3px", paddingLeft: "2px" }}>
      &nbsp;EN
    </div>
  );
  const CustomUncheckedIcon = () => (
    <div style={{ color: "white" }}> &nbsp; த</div>
  );
  const handleChange = (nextChecked: any) => {
    setChecked(nextChecked);
    console.log(checked);
  };
  initializeI18n(checked == true ? "ta" : "en");
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const QA = [
    {
      id: 1,
      question: "What is the Makkaludan Stalin App?",
      answer:
        "The Makkaludan Stalin App is a bilingual platform connecting citizens with Hon. Chief Minister Thiru M.K. Stalin. It's designed to provide direct communication, updates on DMK initiatives, personalized schemes, and a way for you to engage in shaping Tamil Nadu's future.",
    },
    {
      id: 2,
      question:
        "How can I connect with Hon. Chief Minister Thiru M.K. Stalin through the app?",
      answer:
        'You can connect with the Chief Minister through the "Ungalil Oruvan" feature. This allows you to ask questions, share thoughts, and directly engage in meaningful conversations.',
    },
    {
      id: 3,
      question: "What updates can I expect from the app?",
      answer:
        'Stay informed about the latest initiatives undertaken by Party Chief, breaking news, and significant government actions through the "Flash News" feature. We keep you updated, ensuring you\'re always in the loop.',
    },
    {
      id: 4,
      question: 'How does the "Know Your Constituency" feature work?',
      answer:
        "This feature provides insights into the initiatives implemented by the DMK government in your assembly constituency. Learn about local government actions that impact your community.",
    },
    {
      id: 5,
      question: 'What is "Know Your Schemes," and how does it benefit me?',
      answer:
        '"Know Your Schemes" helps you discover government schemes tailored to your needs. You can check your eligibility, learn about benefits, and understand the application process for various schemes by simply taking a short Questionnaire.',
    },
    {
      id: 6,
      question: "Why is the app bilingual?",
      answer:
        "The app is bilingual in both English and Tamil to ensure easy access to information for a broader audience. We believe in promoting inclusivity and making government initiatives accessible to everyone.",
    },
    {
      id: 7,
      question: 'What is the purpose of the "In Media" section?',
      answer:
        'The "In Media" section highlights articles and media coverage featuring the Chief Minister and the DMK government. It promotes transparency, awareness, and provides external sources of information, validating and serving as a testament to the government\'s actions.',
    },
    {
      id: 8,
      question: "How often will new content be added to the app?",
      answer:
        "We strive to provide timely updates and relevant content to keep you engaged and informed. The app will have regular updates, ensuring you have access to the latest information.",
    },
    {
      id: 9,
      question: "Is there a way to quickly catch up on updates?",
      answer:
        'Absolutely! The "Shorts" feature offers 30-second videos highlighting the latest initiatives of Thalaivar M.K. Stalin and his visionary government. Stay informed on the go!',
    },
    {
      id: 10,
      question: "How do I download the Makkaludan Stalin App?",
      answer:
        'You can download the app from the official app stores (Google Play Store, Apple App Store). Search for "Makkaludan Stalin" and get ready to connect, engage, and stay informed!',
    },
  ];
  const QATamil = [
    {
      id: 1,
      question: " மக்களுடன் ஸ்டாலின் செயலி எதற்கு ?",
      answer:
        "மக்களுடன் ஸ்டாலின் செயலி என்பது கழகத் தலைவரும் மாண்புமிகு முதலமைச்சருமான திரு.மு.க.ஸ்டாலின் அவர்களையும் பொது மக்களையும் இணைக்கும் வகையில் உருவாக்கப்பட்ட செயலியாகும். மேலும் இச்செயலி திராவிட மாடல் ஆட்சி மக்களுக்கென்று மேற்கொண்டு வரும் முன்முயற்சிகளையும், பிரத்தியேகமான திட்டங்களையும், தமிழ்நாட்டின் எதிர்காலத்தை வடிவமைப்பதற்கான வாய்ப்புகளையும் வழங்குகிறது.",
    },
    {
      id: 2,
      question:
        "மாண்புமிகு முதலமைச்சர் திரு மு.க.ஸ்டாலினுடன் இந்தச் செயலி மூலம் நான் எவ்வாறு தொடர்பு கொள்ள முடியும்?",
      answer:
        'உங்களில் ஒருவன்" அம்சமானது நீங்கள் கழகத்தலைவரிடம் கேட்க விரும்பும் கேள்விகளைக் நேரடியாகக் கேட்டு அதற்குரிய பதிலைப் பெறவும், உங்கள் எண்ணங்களை அவருடன் பகிர்ந்துகொண்டு அர்த்தமுள்ள உரையாடலைத் தொடரவும் உதவுகிறது.',
    },
    {
      id: 3,
      question:
        " இச்செயலியின் வழியே எத்தகைய புதுப்பிப்புகளை நாங்கள் எதிர்பார்க்கலாம்?",
      answer:
        '"சிறப்புச் செய்திகள்" அம்சத்தின் மூலம் கழகத் தலைவர் மேற்கொண்ட சமீபத்திய முன்முயற்சிகள், முக்கிய செய்திகள் மற்றும் குறிப்பிடத் தக்க அரசாங்க நடவடிக்கைகள் பற்றி அறிந்துகொள்ள இயலும். இச்செயலி, நீங்கள் எப்போதும் புதுப்பிக்கப்பட்ட தகவலை உடனுக்குடன் பெறுவதை உறுதிசெய்யும்.',
    },
    {
      id: 4,
      question:
        'உங்கள் தொகுதியைத் தெரிந்துகொள்ளுங்கள்" அம்சம் எவ்வாறு செயல்படுகிறது?',
      answer:
        "உங்கள் சட்டமன்றத் தொகுதியில் கழக அரசு செயல்படுத்திய சாதனைகள் குறித்த விவரங்களையும், அரசாங்கத்தால் எடுக்கப்பட்டுள்ள முன்னெடுப்புகள் குறித்த தகவல்களையும் மக்கள் தெரிந்துகொள்ள உதவுகிறது.",
    },
    {
      id: 5,
      question: ' "திட்டங்கள்" என்றால் என்ன, அது எனக்கு எவ்வாறு பயனளிக்கிறது?',
      answer:
        '"திட்டங்கள்" அம்சமானது, உங்களுக்கு ஏற்ற அரசாங்க திட்டங்களைக் கண்டறியவும், அதைப் பற்றிய பயன்களை நீங்கள் தெரிந்துகொள்ளவும் உதவுகிறது. மேலும் கொடுக்கப்பட்டுள்ள வினாப்பட்டியலுக்கு ஏற்பப் பதிலளித்து பல்வேறு திட்டங்களுக்கான விண்ணப்ப செயல்முறையைத் தெரிந்துகொண்டு, திட்டங்கள்மூலம் நீங்கள் பயன்பெறலாம்.',
    },
    {
      id: 6,
      question: "மக்களுடன் ஸ்டாலின் செயலி ஏன் இருமொழி தளமாக உள்ளது?",
      answer:
        " அனைவருக்கும் அனைத்தும் என்று கழகத் தலைவர் அவர்கள் எப்போதும் சொல்லும்படி பெருவாரியான பயனர்கள் அணுகிடும் வகையில் தகவல்களை எளிதில் அனைத்து தரப்பினரிடமும் கொண்டுசேர்த்திட ஆங்கிலம் மற்றும் தமிழ் ஆகிய இருமொழிகளில் இச்செயலி உருவாக்கப்பட்டுள்ளது.",
    },
    {
      id: 7,
      question:
        " எத்தகைய இடைவெளியில் புதிய உள்ளடக்கங்கள் செயலியில் சேர்க்கப்படும்?",
      answer:
        "உங்களுக்கு உடனுக்குடன் தகவல்களை வழங்க முறையான நேரத்தில் புதுப்பிப்புகளையும் மற்றும் பொருத்தமான உள்ளடக்கத்தையும் நாங்கள் வழங்குவதை நோக்கமாகக் கொண்டுள்ளோம். இச்செயலி நிலையான புதுப்பிப்புகளைப் பெற்று நீங்கள் எப்போதும் புதிய தகவல்களை அணுகுவதை உறுதி செய்யும்.",
    },
    {
      id: 8,
      question: " ஊடகம் பிரிவின் நோக்கம் என்ன?",
      answer:
        "'ஊடகம்' அம்சத்தின் வழியே மாண்புமிகு முதலமைச்சர் மற்றும் கழக அரசு பற்றிய அறிக்கைகள் மற்றும் ஊடகங்களில் வெளியாகும் செய்திகளைக் காண இயல்வதோடு, அரசாங்கத்தின் செயல்பாடுகளை வெளிப்படையாகத் தெரிந்துகொள்ளவும் உதவுகிறது.",
    },
    {
      id: 9,
      question:
        " புதுப்பிப்புகளைக் காணொளி வாயிலாகப் பெறுவதற்கு ஏதேனும் அம்சம் இருக்கிறதா?",
      answer:
        'ஆம் ! மாண்புமிகு முதலமைச்சர் மு.க.ஸ்டாலின் அவர்களின் தலைமையிலான தொலைநோக்கு அரசின் சமீபத்திய முன்முயற்சிகளை, 30-விநாடி காணொளிக் காட்சிகள் மூலம் காண "சார்ட்ஸ்" அம்சமானது வழிவகை செய்கிறது.',
    },
    {
      id: 10,
      question: "மக்களுடன் ஸ்டாலின் செயலியைப் எவ்வாறு பதிவிறக்கம் செய்வது?",
      answer:
        'அதிகாரப்பூர்வ ஆப் ஸ்டோர்களிலிருந்து (கூகுள் பிளே ஸ்டோர், ஆப்பிள் ஆப் ஸ்டோர்) "மக்களுடன் ஸ்டாலின்" செயலியைப் பதிவிறக்கம் செய்து கழக நிகழ்வுகளையும், அரசாங்க நடவடிக்கைகள் குறித்த தகவல்களையும் உடனுக்குடன் தெரிந்துகொள்ளுங்கள்.',
    },
  ];

  const FAQQuestions = () => {
    const QALang = checked == true ? QATamil : QA;
    return (
      <>
        {QALang.map((obj: any, index: number) => (
          <div key={index}>
            <div className="question font-bold pb-2">
              {obj.id}. {obj.question}
            </div>
            <div className="answer pb-2">{obj.answer}</div>
          </div>
        ))}
      </>
    );
  };
  const userAgent = navigator.userAgent || navigator.vendor;
  let redirectUrl = "";
  if (/android/i.test(userAgent)) {
    redirectUrl =
      "https://play.google.com/store/search?q=makkaludan%20stalin&c=apps";
  } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
    redirectUrl =
      "https://apps.apple.com/in/app/makkaludan-stalin/id6461120102";
  } else {
    console.log("This website is intended for mobile devices.");
  }

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");

  const openVideoModal = (videoUrl: any) => {
    debugger;
    setSelectedVideo(videoUrl);
    setModalOpen(true);
    console.log("fdgdfgdg", videoUrl);
  };

  const closeVideoModal = () => {
    setSelectedVideo("");
    setModalOpen(false);
  };
  const removePlay = "&controls=0&modestbranding=1";
  return (
    <section
      className={`home-page relative text-white bg-[url('../public/MobileBg.png')] w-full bg-black bg-cover bg-center h-full  lg:bg-[url('../public/WebBG.png')] lg:h-screen ${checked == true ? "font-sans" : ""
        }`}
    >
      <div className="absolute w-full text-end top-4 right-4 z-[9]">
        <Switch
          onChange={handleChange}
          checked={checked}
          checkedIcon={<CustomCheckedIcon />}
          uncheckedIcon={<CustomUncheckedIcon />}
          className="react-switch"
        />
      </div>

      <div
        className={
          "homepage-container h-screen overflow-y-auto  pt-12 pl-4 pr-4 lg:p-4 lg:px-12 md:pt-8 xl:pt-16 md:px-8"
        }
      >
        <div className="mks-icon inline-block">
          <img className="w-[60px]" src={MKSLogo} alt="" />
        </div>
        <div className="mks-title  inline-block font-semibold text-xl align-top pt-4 pb-4 lg:text-3xl lg:pt-2 lg:pl-4 md:text-2xl md:pl-2">
          {t("title")}
        </div>
        {redirectUrl && <RedirectToStore url={redirectUrl} />}

        <div className="mks-desccription-container pt-4 lg:pt-0 lg:h-[60%]">
          <div className="mks-desc lg:w-[50%] lg:h-full lg:inline-block lg:align-top lg:pl-2">
            <h1
              className={`mks-download-header text-2xl  font-semibold leading-[35px] lg:text-2xl lg:pt-0`}
            >
              <div className="lg:inline-block md:inline-block">
                {t("download")} &nbsp;
              </div>
              <div className="lg:inline-block md:inline-block">
                {t("makkaludan_stalin")}
              </div>
            </h1>
            <div
              className={`mks-download-description text-left pr-4 pt-4 pb-8 text-sm md:w-[85%]   lg:text-sm xl:text-base xl:pb-8 ${checked == true
                ? "text-sm md:text-sm lg:text-sm lg:w-[95%] "
                : "text-sm md:text-base lg:text-sm lg:w-[80%]"
                }`}
            >
              {t("description")}
            </div>
            <div className="tutorial-section hidden lg:block w-full">
              <div className="tutorial-section pb-2 xl:pb-12">
                <div className="w-[80%]">
                  <div className="tutorial-videos flex flex-row  pl-2">
                    {youtubeVideos.map((item, index) => (
                      <div
                        className="inline-block tut-video w-[18%] mr-4 h-32 "
                        key={index}
                        onClick={() => openVideoModal(item.link)}
                      >
                        <iframe
                          className="tut-video w-[100%] h-full border-0"
                          src={item.link + removePlay}
                          title="YouTube video player"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          style={{ pointerEvents: "none" }}
                        ></iframe>
                      </div>
                    ))}
                  </div>
                  <div className="  pt-6">
                    <button
                      onClick={openModal}
                      className="py-[6px] w-[100%] rounded-xl button-background"
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 384 512"
                          fill="white"
                          className="inline-block mr-2"
                        >
                          <path d="M32 32C32 14.3 46.3 0 64 0S96 14.3 96 32V240H32V32zM224 192c0-17.7 14.3-32 32-32s32 14.3 32 32v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V192zm-64-64c17.7 0 32 14.3 32 32v48c0 17.7-14.3 32-32 32s-32-14.3-32-32V160c0-17.7 14.3-32 32-32zm160 96c0-17.7 14.3-32 32-32s32 14.3 32 32v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V224zm-96 88l0-.6c9.4 5.4 20.3 8.6 32 8.6c13.2 0 25.4-4 35.6-10.8c8.7 24.9 32.5 42.8 60.4 42.8c11.7 0 22.6-3.1 32-8.6V352c0 88.4-71.6 160-160 160H162.3c-42.4 0-83.1-16.9-113.1-46.9L37.5 453.5C13.5 429.5 0 396.9 0 363V336c0-35.3 28.7-64 64-64h88c22.1 0 40 17.9 40 40s-17.9 40-40 40H96c-8.8 0-16 7.2-16 16s7.2 16 16 16h56c39.8 0 72-32.2 72-72z" />
                        </svg>
                      </span>
                      <span>{t("faq")}</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="qr-msg-section flex flex-row pt-8 lg:w-[90%] lg:p-2 lg:items-center ">
                <div className="qr-scanner w-full pt-4 lg:w-[30%]">
                  <img
                    className="mx-auto rounded-2xl h-full "
                    src={QR}
                    alt=""
                  />
                </div>
                <div className="sms-section lg:w-full lg:p-0 lg:pt-4 lg:pl-4 ">
                  <div className="sms-desc text-xl text-left pb-2 lg:pb-4">
                    {t("sms_description")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mks-right hidden lg:inline-block lg:w-[50%] lg:h-screen lg:absolute lg:top-0 ">
            <div className="h-inherit relative">
              <img
                src={BlackWeb}
                className="absolute right-0 w-full h-screen"
              />
              <img
                src={MobileDisplay}
                className="absolute top-6 left-[50%] translate-x-[-50%] w-[95%]"
              />
            </div>
            <div className="download-options flex flex-row justify-evenly py-8 md:justify-start  lg:justify-normal lg:py-2 absolute bottom-4 right-8">
              <a
                href="https://play.google.com/store/search?q=makkaludan%20stalin&c=apps"
                target="_blank"
              >
                <div className="playstore-container flex flex-row bg-[#2B2B2B] rounded-xl h-20 w-44 items-center md:w-56 md:mr-4 lg:mr-8 lg:w-60">
                  <div className="icon w-[35%] text-center">
                    <img
                      className="w-[50px] p-[10px] mx-auto"
                      src={Playstore}
                      alt=""
                    />
                  </div>
                  <div className="title text-white">
                    <div className="text-[10px]">{t("google_title")}</div>
                    <div className="text-base font-bold">{t("play_store")}</div>
                  </div>
                </div>
              </a>
              <a
                href="https://apps.apple.com/in/app/makkaludan-stalin/id6461120102"
                target="_blank"
              >
                <div className="app-store flex flex-row bg-[#2B2B2B] rounded-xl h-20 w-44 items-center  md:w-56 lg:w-60">
                  <div className="icon w-[35%] text-center">
                    <img
                      className="w-[50px] p-[10px] mx-auto"
                      src={Appstore}
                      alt=""
                    />
                  </div>
                  <div className="title text-white">
                    <div className="text-[12px]">{t("ios_title")}</div>
                    <div className="text-base font-bold">{t("app_store")}</div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="app-image relative pt-12 lg:hidden xl:hidden ">
            <img src={Black} />
            <img
              src={MobileDisplay}
              className="absolute top-16 right-0 w-[90%]"
            />
          </div>
          <div className="tutorial-section lg:hidden xl:hidden pb-8">
            <div className="tutorial-videos text-center flex justify-evenly">
              {youtubeVideos.map((item, index) => (
                <div
                  className="inline-block tut-video w-[18%] mr-4 h-32"
                  key={index}
                  onClick={() => openVideoModal(item.link)}
                >
                  <iframe
                    className="tut-video w-[100%] h-full border-0"
                    src={item.link + removePlay}
                    title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    style={{ pointerEvents: "none" }}
                  ></iframe>
                </div>
              ))}
            </div>
            <button
              onClick={openModal}
              className="px-8 py-[6px] mt-4 w-[100%] rounded-xl  button-background"
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 384 512"
                  fill="white"
                  className="inline-block mr-2"
                >
                  <path d="M32 32C32 14.3 46.3 0 64 0S96 14.3 96 32V240H32V32zM224 192c0-17.7 14.3-32 32-32s32 14.3 32 32v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V192zm-64-64c17.7 0 32 14.3 32 32v48c0 17.7-14.3 32-32 32s-32-14.3-32-32V160c0-17.7 14.3-32 32-32zm160 96c0-17.7 14.3-32 32-32s32 14.3 32 32v64c0 17.7-14.3 32-32 32s-32-14.3-32-32V224zm-96 88l0-.6c9.4 5.4 20.3 8.6 32 8.6c13.2 0 25.4-4 35.6-10.8c8.7 24.9 32.5 42.8 60.4 42.8c11.7 0 22.6-3.1 32-8.6V352c0 88.4-71.6 160-160 160H162.3c-42.4 0-83.1-16.9-113.1-46.9L37.5 453.5C13.5 429.5 0 396.9 0 363V336c0-35.3 28.7-64 64-64h88c22.1 0 40 17.9 40 40s-17.9 40-40 40H96c-8.8 0-16 7.2-16 16s7.2 16 16 16h56c39.8 0 72-32.2 72-72z" />
                </svg>
              </span>
              <span>{t("faq")}</span>
            </button>
          </div>

          <div className="sms-section lg:hidden pt-4 pb-8">
            <div className="sms-desc text-left pb-4">
              {t("sms_description")}
            </div>
            <div className="qr-scanner w-full pt-4 md:pt-16 md:pb-10 lg:hidden">
              <img
                className="mx-auto rounded-2xl w-[30%] h-40"
                src={QR}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Modal"
      >
        <div className="modal-content-container p-4">
          <h1 className="modal-header text-base text-center uppercase font-semibold pb-4">
            {t("faq")}
          </h1>
          <FAQQuestions />
          <button className="absolute top-3 right-4" onClick={closeModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1.5em"
              viewBox="0 0 384 512"
            >
              <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
            </svg>
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeVideoModal}
        contentLabel="Modal"
        className="video-modal"
      >
        <div className="model_iframe_div h-full w-full flex items-center justify-center ">
          <iframe
            src={selectedVideo}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            className="w-[80%] h-[80%]"
            allowFullScreen
          ></iframe>
        </div>
        <button
          className="absolute top-2 text-white text-2xl right-4 font-extrabold"
          onClick={closeVideoModal}
        >
          X
        </button>
      </Modal>
    </section>
  );
};
export default HomePage;
