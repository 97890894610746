import { useEffect } from 'react';

function RedirectToStore({ url }:any) {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return null;
}

export default RedirectToStore;